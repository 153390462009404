:root {
  // Note: Custom variable values only support SassScript inside `#{}`.

  // Theme colors variables
  @each $color, $value in $theme-colors {
    --#{$prefix}#{$color}: #{$value};
  }

  // Scroll margin top and Stack sticky top related
  --#{$prefix}scroll-mt: calc(#{$header-height} + #{$global-scroll-margin-top});

  // Set breadcrumb height to 0px if breadcrumb is disabled
  --#{$prefix}breadcrumb-height: 0px;

  // hr style
  --#{$prefix}hr-background-color: #{darken($global-border-color, 5%)};
  --#{$prefix}hr-before-color: #{lighten($single-link-hover-color, 4%)};

  // Scrollbar related variables
  --#{$prefix}scrollbar-thumb-color: #{$scrollbar-color};
  --#{$prefix}scrollbar-thumb-hover-color: #{$scrollbar-hover-color};
  --#{$prefix}scrollbar-track-color: transparent;
  --#{$prefix}scrollbar-width: thin;
  --#{$prefix}scrollbar-width-legacy: 12px;

  // TODO  migrate SCSS variables to CSS variables
}

// Dark theme
[data-theme=dark] {
  @each $color, $value in $theme-colors {
    --#{$prefix}#{$color}: #{darken($value, 5%)};
  }

  --#{$prefix}hr-background-color: #{lighten($global-border-color-dark, 5%)};
  --#{$prefix}hr-before-color: #{darken($global-link-hover-color-dark, 4%)};
}
