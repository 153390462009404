.page {
  position: relative;
  @extend %page-style;

  .content {
    [id] {
      scroll-margin-top: var(--#{$prefix}scroll-mt);
    }
  }

  @include blur;
}

@import '_single';
@import '_special';
@import '_archive';
@import '_home';
@import '_404';
@import '_offline';
@import '_friends';
